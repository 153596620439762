import React from "react";
import { useStaticQuery, graphql } from "gatsby";

const ValuePropsPpc = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      icon1: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Attorney owned company..svg"
        }
      ) {
        publicURL
      }
      icon2: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/Friendly, attentive team..svg"
        }
      ) {
        publicURL
      }
      icon3: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/No loan is too complex..svg"
        }
      ) {
        publicURL
      }
      icon4: file(
        relativePath: {
          eq: "0.0 Repeating Modules/Why Us/No loan is too complex..svg"
        }
      ) {
        publicURL
      }
    }
  `);

  const HeadingTag = headingLevel || "h2";

  const content = [
    {
        icon: data.icon4.publicURL,
        heading: "Retirement assistance",
        text: "Convert inaccessible equity into immediate cash.",
      },  
    {
      icon: data.icon3.publicURL,
      heading: "Debt consolidation",
      text: "Manage repayments more easil",
    },
    {
      icon: data.icon1.publicURL,
      heading: "Tax-free cash",
      text: "That can be used for nearly anything you want!",
    },
    {
      icon: data.icon2.publicURL,
      heading: "Foreclosure avoidance",
      text: "Keep your home and get back on track.",
    },
  ];

  return (
    <section className={`${className || "my-20 md:mb-32 md:px-5"}`}>
      <div className="container">
        <header className=" mb-10 text-center md:mb-14">
          <HeadingTag>
          Reverse Mortgage Benefits
          </HeadingTag>
          <p>
          Here are a few reasons why Orange County, CA, homeowners decide to go mortgage-free:
          </p>
        </header>

        <div className="grid gap-y-8 text-center justify-center md:grid-cols-4 md:gap-x-10 lg:gap-x-20">
          {content.map((content, i) => {
            return (
              <div key={i}>
                <img
                  src={content.icon}
                  alt={content.heading}
                  className="mb-3 mx-auto"
                />
                <div>
                  <p className="heading-six mb-3">{content.heading}</p>
                  <p className="mb-0">{content.text}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default ValuePropsPpc;
