import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
// import styled from "@emotion/styled";
// import tw from "twin.macro";

import { mapEdgesToNodes } from "../../lib/helpers";
// import Slider from "../Slider/SliderMobileCards";
import quote from "../../images/0.0 Repeating Modules/Reviews/quotes.svg";

// const StyledSlider = styled.div`
//   .slick-arrow {
//     ${tw`w-auto top-0 transform-none`}
//   }
//   .slick-prev {
//     ${tw`left-0`}
//   }
//   .slick-next {
//     ${tw`right-0`}
//   }
//   .slick-dots {
//     ${tw`relative justify-center items-center space-x-2.5 !flex`}
//     li {
//       ${tw`h-2 w-2 !mr-0 bg-primary-50/40 rounded-full hover:bg-primary-50 transition-colors duration-300 ease-linear`}
//       &.slick-active {
//         ${tw`bg-primary-50`}
//       }
//     }
//   }
// `;

const Testimonial = ({ headingLevel, className }) => {
  const data = useStaticQuery(graphql`
    {
      yelp: file(relativePath: { eq: "Reviews/Yelp-alt.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 61)
        }
      }
      google: file(relativePath: { eq: "Reviews/Google.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 76)
        }
      }
      facebook: file(relativePath: { eq: "Reviews/Facebook.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 99)
        }
      }
      verified: file(relativePath: { eq: "Reviews/Verified.png" }) {
        childImageSharp {
          gatsbyImageData(layout: CONSTRAINED, width: 124)
        }
      }
      testimonials: allSanityTestimonials(
        sort: { fields: [order], order: ASC }
      ) {
        edges {
          node {
            id
            review
            name
            profilePicture {
              asset {
                gatsbyImageData(layout: CONSTRAINED, width: 56)
              }
            }
            businessNameTitle
            featured
            platform
          }
        }
      }
    }
  `);
  const testimonialsNodes = (data || {}).testimonials
    ? mapEdgesToNodes(data.testimonials).filter((items) => items.featured)
    : [];

  return (
    <section className={`mb-20 md:mb-32 ${className}`}>
      <div className="container">
        <div className="grid gap-y-10 md:grid-cols-3 md:gap-x-6 lg:gap-x-10">
          {testimonialsNodes.slice(0, 3).map((testimonial, i) => {
            return (
              <div
                key={i}
                className="border-2 border-black bg-white px-6 py-8 text-center"
              >
                <img src={quote} alt="Quote" className="mx-auto mb-2.5" />
                <blockquote>
                  <footer className="mb-5">
                    <cite className="not-italic">
                      <div className="mb-1.5 font-bold text-gray-900">
                        {testimonial.name}
                      </div>
                      <div className="text-sm font-bold uppercase tracking-wide text-gray-400">
                        {testimonial.businessNameTitle}
                      </div>
                    </cite>
                  </footer>
                  <q className="mb-6 block">{testimonial.review}</q>

                  {testimonial.platform === "Google" && (
                    <GatsbyImage
                      image={data.google.childImageSharp.gatsbyImageData}
                      loading="lazy"
                      className="mx-auto"
                    />
                  )}

                  {testimonial.platform === "Yelp" && (
                    <GatsbyImage
                      image={data.yelp.childImageSharp.gatsbyImageData}
                      loading="lazy"
                      className="mx-auto"
                    />
                  )}

                  {testimonial.platform === "Facebook" && (
                    <GatsbyImage
                      image={data.facebook.childImageSharp.gatsbyImageData}
                      loading="lazy"
                      className="mx-auto"
                    />
                  )}

                  {testimonial.platform === "Verified" && (
                    <GatsbyImage
                      image={data.verified.childImageSharp.gatsbyImageData}
                      loading="lazy"
                      className="mx-auto"
                    />
                  )}
                </blockquote>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Testimonial;
