import React from "react";
import { graphql, Link } from "gatsby";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Testimonials from "../components/Repeating/Testimonials";
import ValueProps from "../components/Repeating/ValuePropsPpc";
import CallToAction from "../components/Repeating/cta-ppc";
import ButtonSolid from "../components/Button/ButtonSolid";

import topLeft from "../images/4.0 About/top-left.svg";
import topRight from "../images/4.0 About/top-right.svg";
import bottomLeft from "../images/4.0 About/bottom-left.svg";
import bottomRight from "../images/4.0 About/bottom-right.svg";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Orange County Reverse Mortgage Loan | Nikkael Home Loans"
        description="Our brokers at Nikkael Home Loans will help you find the best reverse mortgage loan in Orange County to fit your goals. Contact us today!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 pt-10 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-8 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="order-2 md:order-1">
              <h1>Your Orange County Reverse Mortgage Broker</h1>
              <p>
              Eliminate monthly mortgage payments while staying in your home! Team up with our Nikkael professionals to secure a reverse mortgage loan today.
              </p>
              <p>As an attorney-owned and operated mortgage company, no situation is too complex for our experts to handle. You’ll get superior service and options that best complement your specific situation, all in a pressure-free environment. That’s the Nikkael Home Loan advantage.</p>
              <ButtonSolid modal="modal-contact" text="Contact Us" />
            </div>
            <div className="order-1 md:order-2">
              <StaticImage
                src="../images/PPC/1.0 Orange County Hero.png"
                loading="lazy"
                width={560}
              />
            </div>
          </div>
        </div>
      </section>
      <section className="mb-20 md:mb-32">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/PPC/2.0 Reverse Mortgage.png"
                loading="lazy"
                width={560}
              />
            </div>
            <div>
              <h2>You’re eligible for a reverse mortgage if you…</h2>
              
              <ul className="styled-list-checkmark mb-10">
                <li>
                Are 62 or older
                </li>
                <li>Use the home as your primary residence for the duration of your reverse mortgage loan</li>
                <li>Have sufficient equity in the home</li>
                <li>Meet with a HUD-approved mortgage counselor</li>
              </ul>
              <ButtonSolid href="/request-rates/" text="Get Pre-Approval Now!" />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 md:mb-32">
        <div className="container">
        <h2 className="text-center">Ready to turn equity into cash?</h2>
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div className="bg-[#DEE7EA] rounded-3xl p-[10%] md:p-[64px] text-center justify-center">
            <StaticImage
                src="../images/PPC/3.0 Cash Flow.svg"
                loading="lazy"
                width={64}
              />
              <p className="heading-six my-6 w-[315px] mx-auto">Yes, I’m excited to increase my monthly cash flow. </p>
              <ButtonSolid className="rounded-[40px] bg-[#3D9CB1] hover:bg-[#3D9CB1]/[.75] px-[60px] py-[10px]" modal="modal-contact" text="Cash Out Now" />
            </div>
            <div className="bg-[#DEE7EA] rounded-3xl p-[10%] md:p-[64px] text-center justify-center">
            <StaticImage
                src="../images/PPC/3.0 Mortgage Payments.svg"
                loading="lazy"
                width={64}
              />
              <p className="heading-six my-6 w-[315px] mx-auto">I’d like to learn more about eliminating my mortgage payments.</p>
              <ButtonSolid className="rounded-[40px] bg-[#3D9CB1] hover:bg-[#3D9CB1]/[.75] px-[60px] py-[10px]" href="/reverse-mortgage-orange-county/" text="Explore More" />
            </div>
          </div>
        </div>
      </section>
      <ValueProps />
      <Testimonials />
      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Global/Open Graph/twitter-home.jpg" }
    ) {
      publicURL
    }
    teamMembers: allSanityTeamMember(sort: { fields: [order], order: ASC }) {
      edges {
        node {
          name
          slug {
            current
          }
          title
          headshot {
            asset {
              gatsbyImageData(layout: CONSTRAINED, width: 746)
            }
          }
        }
      }
    }
  }
`;

export default Page;
